<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    class="row custom-options-checkable g-1"
  >
    <div
      v-for="radio in parameter.values"
      :key="radio.id"
      class="col-md-4"
    >
      <input
        :id="'level-' + radio.id"
        :name="'level-' + parameter.id"
        class="custom-option-item-check"
        type="radio"
        :disabled="!convo.open_applicants"
        :value="radio.id"
        :checked="answer ? answer.value == radio.id : false"
        @change="submitAnswer($event)"
      />
      <label
        class="custom-option-item p-1"
        :for="'level-' + radio.id"
      >
        <span class="d-flex justify-content-between flex-wrap mb-50">
          <span class="fw-bolder">
            {{ radio.default_value }}
          </span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    answer: {},
    parameter: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  methods: {
    submitAnswer(event) {
      this.$emit('submitAnswer', event.target.value)
    },
  },
}
</script>
