<!-- eslint-disable vue/html-self-closing -->
<template>
  <input
    class="form-control"
    :name="name"
    :placeholder="parameter.attributes ? parameter.attributes.placeholder : ''"
    :disabled="!convo.open_applicants && !edit"
    :type="parameter.type.name"
    :value="value"
    @blur="submitAnswer($event.target.value)"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    parameter: { type: Object, required: true },
    answer: {},
    name: { type: String, required: false, default: '' },
    edit: { type: Boolean, required: false, default: () => false },
  },
  data() {
    return {
      value: null,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      user: 'auth/user',
    }),
  },
  mounted() {
    this.value = this.answer ? this.answer.value : ''
    if (this.parameter.related_field === 'first_name' && !this.value) {
      this.value = this.user.first_name
      this.$emit('submitAnswer', this.value)
    }

    if (this.parameter.related_field === 'last_name_1' && !this.value) {
      this.value = this.user.last_name_1
      this.$emit('submitAnswer', this.value)
    }

    if (this.parameter.related_field === 'last_name_2' && !this.value) {
      this.value = this.user.last_name_2
      this.$emit('submitAnswer', this.value)
    }

    if (this.parameter.related_field === 'email' && !this.value) {
      this.value = this.user.email
      this.$emit('submitAnswer', this.value)
    }
  },
  methods: {
    submitAnswer(event) {
      this.$emit('submitAnswer', event)
    },
  },
}
</script>
