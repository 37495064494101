<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <div
      id="offcanvasAddEducation"
      class="offcanvas offcanvas-bottom offcanvas-bottom--70"
      :class="showModal ? 'show' : ''"
      :style="{ visibility: showModal ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasAddAddEducationLabel"
    >
      <div class="offcanvas-header">
        <h5></h5>
        <button
          type="button"
          class="btn-close text-end"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="toggleModal()"
        ></button>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <form action="">
              <div class="offcanvas-body">
                <fieldset>
                  <div class="card-header mb-0 pb-0">
                    <legend>{{ group.name }}</legend>
                    <p>
                      {{ group.description }}
                    </p>
                  </div>
                  <div class="card-body">
                    <div
                      v-if="group && showModal"
                      class="row"
                    >
                      <Parameter
                        v-for="(parameter) in params"
                        :key="parameter.id"
                        :parameter="parameter"
                        :answer="{value: parameter.answer ? parseAnswer(parameter, index) : '' }"
                        :group-id="group.id"
                        :section-id="sectionId"
                        :index-list="(index - 1)"
                        :save-answer="true"
                        :user-id="userId"
                      />
                    </div>
                    <div class="row">
                      <div class="col">
                        <hr />
                        <button
                          type="button"
                          class="btn btn-success me-1"
                          @click="toggleModal(null, true)"
                        >
                          Save
                        </button>
                        <button
                          v-if="button"
                          type="button"
                          class="btn btn-outline-danger"
                          data-bs-dismiss="offcanvas"
                          @click="toggleModal(true)"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="offcanvas-backdrop fade"
      :class="showModal ? 'show' : ''"
      @click="toggleModal()"
    ></div>
  </div>
</template>

<script>
import Vue from 'vue'
import Parameter from '../Parameter.vue'

export default {
  components: {
    Parameter,
  },
  props: {
    show: { type: Boolean, required: true },
    parameters: { type: [Array, Object], required: true },
    group: { type: Object, required: true },
    sectionId: { type: Number, required: true },
    index: { type: Number, required: true },
    button: { type: Boolean, required: true },
    userId: { type: Number, required: false, default: null },
  },
  data() {
    return {
      showModal: false,
      params: this.parameters,
    }
  },
  watch: {
    show() {
      this.toggleModal()
    },
  },
  methods: {
    async toggleModal(cancel = null, checkRequired = false) {
      if (checkRequired && this.button) {
        let check = true
        await this.$store.dispatch('convoGroup/fetchParameters', [this.group.id, this.$route.params.convo_id ?? this.convo.id, this.sectionId])
        const auxParams = this.$store.getters['convoGroup/parameters']
        this.params.forEach((param, index) => {
          const actualLength = auxParams[index].answer ? Object.keys(JSON.parse(auxParams[index].answer.value)).length : 0
          const customLength = param.answer ? Object.keys(JSON.parse(param.answer.value)).length : 0

          if (param.is_required && actualLength === customLength) {
            check = false
          }
        })

        if (!check) {
          Vue.swal('', 'Please, fill in all the required fields', 'warning')
          return ''
        }
      }

      this.showModal = !this.showModal

      if (cancel) {
        await this.$store.dispatch('convoGroup/deleteAnswer', [this.group.id, this.$route.params.convo_id ?? this.convo.id, this.sectionId, (this.index - 1)])
      }

      await this.$store.dispatch('convoGroup/fetchParameters', [this.group.id, this.$route.params.convo_id ?? this.convo.id, this.sectionId])
      if (!cancel) {
        this.params = this.$store.getters['convoGroup/parameters']
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
      return ''
    },
    parseAnswer(parameter, index) {
      try {
        return JSON.parse(parameter.answer.value)[index - 1]
      } catch (e) {
        return ''
      }
    },
  },
}
</script>
