<!-- eslint-disable vue/html-self-closing -->
<template>
  <div class="row">
    <div class="col-12">
      <div
        class="form-group"
        :class="{'disabled-group': !convo.open_applicants, 'required': parameter.is_required}"
      >
        <label
          class="form-label"
          for="select2-erc-level-1"
        >Level 1</label>
        <select
          id="select2-erc-level-1"
          v-model="selectedLvlOne"
          :disabled="!convo.open_applicants"
          class="select2 form-select"
          @change="submitAnswer($event, 1)"
        >
          <option
            disabled
            value=""
          >
            Please choose a panel
          </option>
          <option
            v-for="option in levelOne"
            :key="option.id"
            :value="option.id"
          >
            {{ option.default_value }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <div
        class="form-group"
        :class="{'disabled-group': (disableLevelTwo && !selectedLvlOne )|| !convo.open_applicants, 'required': parameter.is_required}"
      >
        <label
          class="form-label"
          for="select2-erc-level-2"
        >Level 2</label>
        <select
          id="select2-erc-level-2"
          v-model="selectedLvlTwo"
          class="select2 form-select"
          :disabled="(disableLevelTwo && !selectedLvlOne) || !convo.open_applicants"
          @change="submitAnswer($event, 2)"
        >
          <option
            disabled
            value=""
          >
            Please choose a panel
          </option>
          <option
            v-for="option in levelTwo"
            :key="option.id"
            :value="option.id"
          >
            {{ option.default_value }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <div
        class="form-group"
        :class="{'disabled-group': (disableLevelThree && !selectedLvlTwo) || levelThree.length == 0 || !convo.open_applicants}"
      >
        <label
          class="form-label"
          for="select2-erc-level-3"
        >Level 3</label>
        <select
          id="select2-erc-level-3"
          v-model="selectedLvlThree"
          class="select2 form-select"
          :disabled="(disableLevelThree && !selectedLvlTwo) || levelThree.length == 0 || !convo.open_applicants"
          @change="submitAnswer($event, 3)"
        >
          <option
            disabled
            value=""
          >
            Please choose a panel
          </option>
          <option
            v-for="option in levelThree"
            :key="option.id"
            :value="option.id"
          >
            {{ option.default_value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    answer: {},
    parameter: { type: Object, required: true },
  },
  data() {
    return {
      levelOne: [],
      levelTwo: [],
      levelThree: [],
      selectedLvlOne: '',
      selectedLvlTwo: '',
      selectedLvlThree: '',
      disableLevelTwo: true,
      disableLevelThree: true,
      lvlOne: 0,
      lvlTwo: 1,
      lvlThree: 2,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  watch: {
    answer() {
      this.loadAnswers(false)
    },
  },
  mounted() {
    this.levelOne = this.parameter.values
    this.loadAnswers(true)
  },
  methods: {
    submitAnswer(event, level) {
      if (level === 1) {
        this.levelTwo = []
        this.levelTwo = this.levelOne.find(x => x.id === this.selectedLvlOne).childrens.map(e => ({
          id: e.id,
          default_value: e.name,
          childrens: e.childrens,
        }))
        this.disableLevelTwo = false
        this.disableLevelThree = true
        this.selectedLvlTwo = ''
        this.selectedLvlThree = ''
      } else if (level === 2) {
        const lvl = this.levelTwo.find(x => x.id === this.selectedLvlTwo)
        if (lvl) {
          this.levelThree = lvl.childrens.map(e => ({
            id: e.id,
            default_value: e.name,
          }))

          this.selectedLvlThree = ''
          this.disableLevelThree = false
        }
      }

      this.$emit('submitAnswer', { 0: this.selectedLvlOne, 1: this.selectedLvlTwo, 2: this.selectedLvlThree })
    },
    async loadAnswers(isJson) {
      if (this.answer && this.answer.value) {
        if (isJson) {
          const answers = JSON.parse(this.answer.value)
          if (answers && answers[0]) {
            if (typeof answers[0] === 'number') {
              this.selectedLvlOne = answers[this.lvlOne]
              this.selectedLvlTwo = answers[this.lvlTwo]
              this.selectedLvlThree = answers[this.lvlThree]
            } else {
              this.selectedLvlOne = answers[this.lvlOne][this.lvlOne]
              this.selectedLvlTwo = answers[this.lvlOne][this.lvlTwo]
              this.selectedLvlThree = answers[this.lvlOne][this.lvlThree]
            }
          }
        } else {
          this.selectedLvlOne = this.answer.value[this.lvlOne]
          this.selectedLvlTwo = this.answer.value[this.lvlTwo]
          this.selectedLvlThree = this.answer.value[this.lvlThree]
        }

        if (this.selectedLvlOne) {
          this.levelTwo = this.levelOne.find(x => x.id === this.selectedLvlOne).childrens.map(e => ({
            id: e.id,
            default_value: e.name,
            childrens: e.childrens,
          }))
        }

        if (this.selectedLvlTwo) {
          this.levelThree = this.levelTwo.find(x => x.id === this.selectedLvlTwo).childrens.map(e => ({
            id: e.id,
            default_value: e.name,
          }))
        }
      } else {
        this.selectedLvlOne = ''
        this.selectedLvlTwo = ''
        this.selectedLvlThree = ''

        this.disableLevelTwo = true
        this.disableLevelThree = true
      }
    },
  },
}
</script>
