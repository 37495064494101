<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <quill-editor
      :ref="`quill-${parameter.name}`"
      :value="answer ? answer.value : ''"
      :name="name"
      :disabled="!convo.open_applicants && !edit"
      :data-placeholder="parameter.attributes ? parameter.attributes.placeholder : ''"
      @blur="submitAnswer($event)"
      @change="$emit('saveAnswer', $event)"
    ></quill-editor>
    <input
      type="hidden"
      :name="name"
      :value="text"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    answer: {},
    name: { type: String, required: false, default: '' },
    parameter: { type: [Object, Array], required: false, default: () => [] },
    edit: { type: Boolean, required: false, default: () => false },
  },
  data() {
    return {
      text: '',
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  mounted() {
    this.text = this.answer ? this.answer.value : ''
    this.$emit('saveAnswer', this.$refs[`quill-${this.parameter.name}`])
    this.$refs[`quill-${this.parameter.name}`].quill.root.setAttribute('data-placeholder', this.parameter.attributes && this.parameter.attributes.placeholder ? this.parameter.attributes.placeholder : 'Insert text here...')
  },
  methods: {
    submitAnswer(event) {
      this.text = event.root.innerHTML
      this.$emit('submitAnswer', event.root.innerHTML)
    },
  },
}
</script>
