<!-- eslint-disable vue/html-self-closing -->
<template>
  <div class="row">
    <div class="col-4">
      <select
        v-model="prefix"
        :disabled="!convo.open_applicants"
        class="select2 form-select"
        :value="answer && answer.value ? answer.value.slice(0, 3) : parameter.values[0].id"
      >
        <option
          v-for="option in parameter.values"
          :key="option.id"
          :value="option.default_value"
        >
          {{ option.default_value }}
        </option>
      </select>
    </div>
    <div class="col-8">
      <input
        class="form-control"
        type="tel"
        :disabled="!convo.open_applicants"
        :placeholder="
          parameter.attributes ? parameter.attributes.placeholder : ''
        "
        :value="answer && answer.value && answer.value.split(' ')[1] ? answer.value.split(' ')[1] : (answer.value.split(' ')[0] ? answer.value.split(' ')[0] : '')"
        @change="submitAnswer($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    parameter: { type: Object, required: true },
    answer: {},
  },
  data() {
    return {
      prefix: this.answer && this.answer.value && this.answer.value.split(' ')[1] ? this.answer.value.split(' ')[0] : '+34',
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  methods: {
    submitAnswer(event) {
      this.$emit('submitAnswer', `${this.prefix} ${event.target.value}`)
    },
  },
}
</script>
