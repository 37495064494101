<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    :class="[
      !parameter.column_size || parameter.column_size === 6
        ? 'col-md-6'
        : 'col-md-' + parameter.column_size,
    ]"
  >
    <div
      v-if="answer && answer.value && parameter.type.name == 'file'"
      class="alert alert-success"
    >
      <div class="alert-body">
        <div class="row d-flex align-items-center">
          <div class="col-sm-6">
            <p class="text-secondary">
              File uploaded
            </p>
            <p class="text-dark">
              <strong>{{ answer.value }}</strong>
            </p>
          </div>
          <div class="col-sm-6 d-flex justify-content-sm-end">
            <p>
              <a
                href="javascript:;"
                class="btn text-dark"
                @click="openFile(parameter.answer.id)"
              ><i data-feather="eye"></i> View file</a>
              <button
                id="confirm-text"
                type="button"
                class="btn text-danger"
                title="delete file"
                @click="submitAnswer($event)"
              >
                <i data-feather="trash-2"></i> Delete
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        :id="'parameter-' + parameter.id"
        :class="{ 'required': parameter.is_required && parameter.type.name != 'erc' }"
        class="form-group"
      >
        <!-- Parameter title -->
        <h5
          v-if="parameter.type.name == 'textarea' && parameter.attributes"
          :style="{ height: parameter.type.name == 'textarea' && !parameter.name && !parameter.attributes.label ? '12px' : '' }"
        >
          {{ parameter.attributes ? parameter.attributes.label : '' }}
          <span
            v-if="parameter.type.name == 'textarea' && !parameter.name"
            class="text-danger float-end"
            style="font-weight: 400;font-size: .857rem;"
            :class="{'pe-1': parameter.is_required}"
          >{{ contentLength }}/{{ parameter.attributes && parameter.attributes.char_limit ? parameter.attributes.char_limit : defaultLimit }}</span>
        </h5>
        <label
          v-if="parameter.type.name != 'institution' && parameter.type.name != 'combo-box' && parameter.name"
          class="form-label w-100"
        >
          {{ parameter.name }}
          <span
            v-if="parameter.type.name == 'textarea'"
            class="text-danger float-end"
            :class="{'pe-1': parameter.is_required}"
          >{{ contentLength }}/{{ parameter.attributes && parameter.attributes.char_limit ? parameter.attributes.char_limit : defaultLimit }}</span>
        </label>

        <!-- Parameter fields -->
        <CustomInput
          v-if="
            parameter.type.name == 'text' ||
              parameter.type.name == 'number' ||
              parameter.type.name == 'date' ||
              parameter.type.name == 'email'
          "
          :parameter="parameter"
          :answer="answer"
          @submitAnswer="submitAnswer"
        />

        <File
          v-if="parameter.type.name == 'file'"
          :options="dropzoneOptions"
          @submitAnswer="submitAnswer"
        />

        <TextArea
          v-if="parameter.type.name == 'textarea'"
          :answer="answer"
          :parameter="parameter"
          @submitAnswer="submitAnswer"
          @saveAnswer="updateAnswer"
        />

        <Publication
          v-if="parameter.type.name == 'publication'"
          :answer="answer"
          :parameter="parameter"
          :group-id="groupId"
          @submitAnswer="submitAnswer"
        />

        <Select
          v-if="parameter.type.name == 'select'"
          :parameter="parameter"
          :answer="answer"
          @submitAnswer="submitAnswer"
        />

        <RadioButton
          v-if="parameter.type.name == 'radio'"
          :parameter="parameter"
          :answer="answer"
          @submitAnswer="submitAnswer"
        />

        <Phone
          v-if="parameter.type.name == 'phone'"
          :parameter="parameter"
          :answer="answer"
          @submitAnswer="submitAnswer"
        />

        <Institution
          v-if="parameter.type.name == 'institution'"
          :parameter="parameter"
          :answer="answer"
          :group-id="groupId"
          :section-id="sectionId"
          @submitAnswer="submitAnswer"
          @copyLink="copyLink"
        />

        <ERC
          v-if="parameter.type.name == 'erc'"
          :parameter="parameter"
          :answer="answer"
          @submitAnswer="submitAnswer"
        />

        <ComboBox
          v-if="parameter.type.name == 'combo-box'"
          :parameter="parameter"
          :answer="answer"
          @submitAnswer="submitAnswer"
        />

        <div class="form-text">
          {{ parameter.attributes ? parameter.attributes.hint : "" }}
        </div>
        <div
          class="alert alert-danger mt-1 p-1"
          :style="{ display: displayErrors }"
        >
          <template v-for="error in errors">
            {{ error }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Form from 'vform'
import { mapGetters } from 'vuex'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import * as clipboard from 'clipboard-polyfill'
import CustomInput from './input-types/CustomInput.vue'
import File from './input-types/File.vue'
import TextArea from './input-types/TextArea.vue'
import Select from './input-types/Select.vue'
import RadioButton from './input-types/RadioButton.vue'
import Phone from './input-types/Phone.vue'
import Institution from './input-types/Institution.vue'
import ComboBox from './input-types/ComboBox.vue'
import Publication from './input-types/Publication.vue'
import ERC from './input-types/ERC.vue'

export default {
  name: 'Parameter',
  components: {
    CustomInput,
    File,
    TextArea,
    Select,
    RadioButton,
    Phone,
    Institution,
    ComboBox,
    Publication,
    ERC,
  },
  props: {
    parameter: { type: Object, required: true },
    groupId: { type: Number, required: false, default: 0 },
    sectionId: { type: Number, required: true },
    userId: { type: Number, required: false, default: null },
    answer: {},
    indexList: { type: Number, required: false, default: null },
    saveAnswer: { type: Boolean, required: true },

  },
  data() {
    return {
      defaultLimit: 2000,
      contentLength: 0,
      form: new Form({}),
      prefix: null,
      groupUrl: Vue.prototype.$groupUrlCv,
      displayErrors: 'none',
      errors: null,
      dropzoneOptions: {
        url: `${Vue.prototype.$groupUrl}/convo-section/save-answer`,
        maxFiles: 1,
        params: {
          parameterId: this.parameter.id,
          groupId: this.groupId,
          convoSectionId: this.sectionId,
          convoId: this.$route.params.convo_id,
          userId: this.userId,
        },
        thumbnailWidth: 150,
        acceptedFiles: 'application/pdf',
        maxFilesize: 5,
        headers: { Authorization: `Bearer ${window.location.href.includes('/intranet') ? localStorage.getItem('accessTokenAdmin') : localStorage.getItem('accessToken')}` },
      },
      saved: false,
    }
  },
  computed: {
    ...mapGetters({
      payload: 'convoSection/payload',
      requiredFields: 'convoSection/requiredFields',
      filledRequiredFields: 'convoSection/filledRequiredFields',
      backConvo: 'convo/convo',
    }),
  },
  mounted() {
    this.replaceIcons()
    this.dropzoneOptions.params.convoId = window.location.href.includes('/intranet') ? this.backConvo.id : this.$route.params.convo_id
    if (this.answer) {
      this.saved = true
    }
  },
  methods: {
    replaceIcons() {
      feather.replace({
        width: 14,
        height: 14,
      })
    },
    async submitAnswer(answer) {
      if (!this.saveAnswer) return

      this.replaceIcons()
      this.form.parameterId = this.parameter.id
      if (this.indexList !== null) {
        this.form.indexList = this.indexList
      }

      if (this.parameter.type.name === 'file') {
        this.answer = null
        this.form.value = null
        Vue.swal('File deleted succesfully', '', 'success')
      } else {
        this.form.value = answer
      }

      this.form.convoId = this.$route.params.convo_id
      this.form.groupId = this.groupId
      this.form.convoSectionId = this.sectionId

      let errors = {}

      try {
        if (window.location.href.includes('/intranet')) {
          this.form.convoId = this.backConvo.id
          this.form.userId = this.userId
          await this.form.post(
            `${Vue.prototype.$groupUrl}/convo-section/save-answer`,
            { headers: { Authorization: `Bearer ${localStorage.getItem('accessTokenAdmin')}` } },
          )
        } else {
          await this.form.post(
            `${Vue.prototype.$groupUrl}/convo-section/save-answer`,
          )
        }
      } catch (e) {
        errors = this.form.errors.errors
      }

      if (errors.value) {
        this.displayErrors = 'block'
        this.errors = errors.value
      } else {
        this.displayErrors = 'none'
        this.errors = null

        if ([58, 59, 60, 61].includes(this.parameter.id)) {
          let leng = 0
          if (this.parameter.answer) {
            leng = Object.keys(JSON.parse(this.parameter.answer.value)).length + 1
          } else {
            leng = 1
          }

          this.$store.dispatch('convoSection/calculatePercentage', [0, (leng - this.filledRequiredFields)])
        }

        if (this.parameter.id == 49) {
          let count = false
          Object.keys(JSON.parse(this.parameter.answer.value)).forEach(key => {
            if (JSON.parse(this.parameter.answer.value)[key] == 23) {
              count = true
            }
          })

          if (!count) {
            count = answer == 23
          }

          if (count && this.filledRequiredFields == 1) {

          } else if (!count && this.filledRequiredFields == 1) {
            this.$store.dispatch('convoSection/calculatePercentage', [0, -1])
          } else if (count && this.filledRequiredFields == 0) {
            this.$store.dispatch('convoSection/calculatePercentage', [0, 1])
          }
        }

        if (this.parameter.is_required && !this.saved) {
          this.$store.dispatch('convoSection/calculatePercentage', [0, 1])
          this.saved = true
        }
      }
    },
    async copyClipboard(text) {
      clipboard.writeText(text).then(
        () => {
          this.$toastr.success('', 'Copied to clipboard')
        },
        e => {
          const textarea = document.createElement('textarea')
          textarea.setAttribute('readonly', true)
          textarea.setAttribute('contenteditable', true)
          textarea.style.position = 'fixed' // prevent scroll from jumping to the bottom when focus is set.
          textarea.value = text

          document.body.appendChild(textarea)

          textarea.focus()
          textarea.select()
          const oldContentEditable = textarea.contentEditable
          const oldReadOnly = textarea.readOnly
          const range = document.createRange()

          textarea.contentEditable = true
          textarea.readOnly = false
          range.selectNodeContents(textarea)

          const s = window.getSelection()
          s.removeAllRanges()
          s.addRange(range)

          textarea.setSelectionRange(0, 999999) // A big number, to cover anything that could be inside the element.

          textarea.contentEditable = oldContentEditable
          textarea.readOnly = oldReadOnly

          document.execCommand('copy')
        },
      )
    },
    async copyLink(parameterId) {
      await this.$store.dispatch('convoSection/getInstitutionUrl', {
        parameterId,
        convoId: this.$route.params.convo_id,
        groupId: this.groupId,
        sectionId: this.sectionId,
      })

      const urlPayload = this.$router.resolve({
        name: 'front.senior-call-upload-institution',
        params: {
          payload: this.payload,
        },
      })

      this.copyClipboard(new URL(urlPayload.href, window.location.origin).href)
    },
    updateAnswer(data) {
      const char_limit = this.parameter.attributes && this.parameter.attributes.char_limit ? this.parameter.attributes.char_limit : this.defaultLimit
      data.quill.deleteText(char_limit, data.quill.getLength() - 1)
      this.contentLength = data.quill.getLength() - 1
    },
    openFile(id) {
      if (window.location.href.includes('/intranet')) {
        window.open(`${this.groupUrl}/${id}?token=${localStorage.getItem('accessTokenAdmin')}`, '_blank', '')
      } else {
        window.open(`${this.groupUrl}/${id}?token=${localStorage.getItem('accessToken')}`, '_blank', '')
      }
    },
  },
}
</script>
