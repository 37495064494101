<!-- eslint-disable vue/html-self-closing -->
<template>
  <select
    class="form-control"
    :value="answer ? answer.value : ''"
    :name="name"
    :disabled="!convo.open_applicants && !edit"
    @change="submitAnswer($event)"
  >
    <option
      value=""
    >
      Select an option
    </option>
    <option
      v-for="option in parameter.values"
      :key="option.id"
      :value="option.id"
    >
      {{ option.default_value }}
    </option>
  </select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    answer: {},
    parameter: { type: Object, required: true },
    name: { type: String, required: false, default: '' },
    edit: { type: Boolean, required: false, default: () => false },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  methods: {
    submitAnswer(event) {
      this.$emit('submitAnswer', event.target.value)
    },
  },
}
</script>
