<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <quill-editor
      v-model="publication"
      :disabled="!convo.open_applicants"
      :value="answer ? answer.value : ''"
      @blur="submitAnswer()"
    ></quill-editor>
    <div class="col-md-12">
      <button
        v-b-toggle="`collapsePublication-${parameter.id}-${groupId}`"
        class="btn btn-sm waves-effect mt-2"
        :class="append ? 'btn-outline-success' : 'btn-outline-dark'"
        role="button"
        @click.prevent
      >
        <i :data-feather="append ? 'plus' : 'check'"></i>
        You may include here a brief justification of your choice.
      </button>
      <b-collapse :id="`collapsePublication-${parameter.id}-${groupId}`">
        <small class="textarea-counter-value float-end">
          <span class="char-count">{{ append ? append.length : 0 }}</span> / 400
        </small>
        <textarea
          v-model="append"
          :disabled="!convo.open_applicants"
          data-length="400"
          class="form-control char-textarea"
          rows="5"
          placeholder=""
          @change="submitAnswer()"
        >
        </textarea>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  props: {
    answer: {},
    parameter: { type: Object, required: true },
    groupId: { type: Number, required: true },
  },
  data() {
    return {
      publication: null,
      append: null,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  watch: {
    append() {
      this.append = this.append.substring(0, 400)
    },
  },
  mounted() {
    if (this.answer && this.answer.value) {
      const parsedAnswer = JSON.parse(this.answer.value)
      this.publication = parsedAnswer.publication
      this.append = parsedAnswer.append
    }
  },
  methods: {
    submitAnswer() {
      this.$emit('submitAnswer', { publication: this.publication, append: this.append })
    },
  },
}
</script>
