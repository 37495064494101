<!-- eslint-disable vue/html-self-closing -->
<template>
  <vue-dropzone
    v-if="convo.open_applicants"
    id="dropzone"
    ref="myVueDropzone"
    :options="options"
    @change="submitAnswer($event)"
  ></vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import { mapGetters } from 'vuex'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    options: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
    }),
  },
  methods: {
    submitAnswer(event) {
      this.$emit('submitAnswer', event.target.value)
    },
  },
}
</script>
