<template>
  <div>
    <!-- offcanvas Repeaters -->
    <div
      id="offcanvasRepeaters"
      class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasRepeatersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasRepeatersLabel"
          class="offcanvas-title"
        >
          <small>Repeaters view</small><br><strong>View repeaters in all convos</strong>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <h4>Search researcher</h4>
        <div class="row d-flex alig-items-end">
          <div class="col-sm-4">
            <label
              for=""
              class="form-label"
            >Name</label>
            <v-select
              v-if="aspirants.length !== undefined"
              v-model="aspirant"
              label="custom_label"
              :options="customUsers"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event, role: 'researcher' })"
            >
              <option
                value=""
                selected
              >
                All areas
              </option>
            </v-select>
          </div>
          <div class="col-sm-8">
            <div class="d-flex align-items-end h-100">
              <button class="btn btn-dark me-1">
                <i data-feather="search" />
              </button>
              <button class="btn btn-outline-dark">
                <i data-feather="refresh-ccw" />
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="aspirant"
          class="card shadow-none bg-transparent border-primary repeater--miniProfile"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="d-flex align-items-center">
                  <div class="avatar me-50">
                    <img
                      :src="require('@/assets/images/portrait/small/avatar-s-11.jpg')"
                      alt="Avatar"
                      width="38"
                      height="38"
                    >
                  </div>
                  <div class="more-info">
                    <h6 class="mb-0">
                      {{ aspirant.name }}
                    </h6>
                    <p class="mb-0">
                      {{ aspirant.hosts.map(e => e.code).join(', ') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <p>Email<strong>{{ aspirant.email }}<a
                  :href="`mailto:${aspirant.email}`"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Open and send a email"
                ><i data-feather="mail" /></a></strong></p>
              </div>
              <div class="col-sm-3">
                <p>Phone<strong>{{ aspirant.phone }}</strong></p>
              </div>
              <!-- <div class="col-sm-2">
                <a
                  href=""
                  class="btn btn-dark btn-sm w-100"
                ><i data-feather="send" /> Send notification</a>
              </div> -->
            </div>
          </div>
        </div>

        <div
          v-if="aspirant"
          class="table-responsive-sm mt-3"
        >
          <div class="row mb-1">
            <div class="col-sm-8 d-flex align-items-center">
              <h4><strong>{{ calls.length }} </strong>calls</h4>
            </div>
          </div>

          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Call</th>
                <th>Area</th>
                <th>AVG Grade</th>
                <th>Status</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="call in calls"
                :key="call.id"
              >
                <td>{{ call.convo.title }}</td>
                <td>
                  <span
                    class="badge bg-light-secondary"
                  >
                    {{ call.convo.code ? call.convo.code : call.convo.year }}
                  </span>
                </td>
                <td>
                  <template
                    v-for="area, index in call.convo.areas.filter(e => {
                      if (!e.parent_id) {
                        return true
                      }
                      return false
                    })"
                  >
                    {{ area.code + (index < (call.convo.areas.length - 1) ? ', ' : ' ') }}
                  </template>
                </td>
                <td>{{ call.grade }}</td>
                <td v-html="call.status_table" />
                <td class="text-end">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="openForm(call)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View form data
                      </b-dropdown-item>
                      <!-- <b-dropdown-item @click="selectedItem = call.user, $store.dispatch('modals/toggleFormEvaluation', true)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View form evaluation
                      </b-dropdown-item> -->
                    </div>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <ShowFormEvaluation :selected="selectedItem" /> -->
    <ShowFormData :selected="selectedItem" />
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'
// import ShowFormEvaluation from '../../admin/academy-call/components/ShowFormEvaluation.vue'
import ShowFormData from '../../admin/academy-call/components/ShowFormData.vue'

export default {
  components: {
    vSelect,
    // ShowFormEvaluation,
    ShowFormData,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      name: null,
      aspirant: null,
      selectedItem: {},
      calls: [],
      convoTypeTitle: 'Senior call',
      convoType: 'senior',
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/convoRepeatersView',
      aspirants: 'users/fakeUsers',
    }),
    customUsers() {
      return this.aspirants.map(e => {
        e.custom_label = `${e.name} (${e.roles.join(', ')})`
        return e
      })
    },
  },
  watch: {
    // whenever question changes, this function will run
    async aspirant() {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$identityUrl}/user/${this.aspirant.id}/get-all-calls`)
      this.calls = resp.data
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
  async mounted() {
    // if (this.aspirants.length === undefined) {
    //   await this.$store.dispatch('users/fetchAspirants')
    // }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    openForm(call) {
      this.$store.dispatch('convo/addConvo', call.convo)
      this.selectedItem = call.user
      this.$store.dispatch('modals/toggleFormData', true)
    },
    closeOffcanva() {
      this.$store.dispatch('modals/toggleConvoRepeatersView', false)
    },
    async onSearch(search) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch('users/fetchFakeUsers', search)
      }
    },
  },
}
</script>
